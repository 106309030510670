"use client";
import classNames from "classnames";
import { HTMLAttributes } from "react";
import { AiFillInfoCircle, AiFillWarning } from "../../../react-icons/ai";
import { GrClose } from "../../../react-icons/gr";
import { IoMdCheckmarkCircle, IoMdCloseCircle } from "../../../react-icons/io";

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: any | any[];
  type?: "info" | "good" | "warning" | "fail" | "neutral";
  iconSize?: number;
  fontSize?: "small" | "medium";
  direction?: "horizontal" | "vertical";
  paddingSize?: number;
  className?: string;
  closeCb?: () => void;
  isOpen?: boolean;
  maxWidthActive?: boolean;
  maxWidth?: number;
  customIcon?: JSX.Element;
}

export function MessageBox({
  children,
  type = "neutral",
  iconSize = 22,
  fontSize = "small",
  paddingSize = 8,
  direction = "horizontal",
  className,
  closeCb,
  maxWidthActive = true,
  maxWidth,
  customIcon,
  isOpen = true,
  ...props
}: Props) {
  return (
    <>
      {isOpen === true && (
        <div
          {...props}
          className={classNames(type, fontSize, className, direction, "message-box")}
          style={{
            padding: paddingSize + " 10px",
            borderRadius: direction === "vertical" ? "35px" : "20px",
            paddingLeft: direction === "vertical" ? "20px" : "15px",
            paddingRight: direction === "vertical" ? "20px" : "15px",
            paddingTop: direction === "vertical" ? "20px" : paddingSize + "px",
            paddingBottom: direction === "vertical" ? "20px" : paddingSize + "px",
            maxWidth: maxWidth
              ? maxWidth + "px"
              : (maxWidthActive == false
                ? "unset"
                : (direction === "vertical"
                  ? "800px"
                  : "500px"
                )
              ),
            ...props.style,
          }}
        >
          {closeCb && (
            <div onClick={() => closeCb()} className="close-btn">
              <GrClose size={18} />
            </div>
          )}
          <div className={"icon"}>
            {customIcon ?? (
              <>
                {type === "fail" && <IoMdCloseCircle size={iconSize} />}
                {type === "info" && <AiFillInfoCircle size={iconSize} />}
                {type === "good" && <IoMdCheckmarkCircle size={iconSize} />}
                {type === "neutral" && <AiFillInfoCircle size={iconSize} color="var(--color-gray6)" />}
                {type === "warning" && <AiFillWarning size={iconSize} />}
              </>
            )}
          </div>

          <div className="message">{children}</div>
        </div>
      )}
    </>
  );
}
