"use client";
import { useEffect, useState } from "react";

// declare let window

export function useMediaQuery(query: string | number, delay = 250): boolean {
  const [mobile, setMobile] = useState(false);

  const setMedia = () => {
    const mediaQuery = `(max-width: ${query}px)`;
    setMobile(window.matchMedia(mediaQuery).matches);
  };

  useEffect(() => {
    let timeoutId = null as any;
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      setMedia();
    }, delay);

    window.addEventListener("resize", setMedia);

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      window.removeEventListener("resize", setMedia);
    };
  }, [mobile, query]);

  return mobile;
}
