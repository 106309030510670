"use client";

export type shortCutKeyType = {
  shortCut: string[];
  // impossible à typer car dans un composant en direct
  command?: (router: any, dispatch: any, state: any) => void;
  name: string;
  key: string;
  description: string;
};

type Props = { item: shortCutKeyType; type: "short" | "long"; style?: React.CSSProperties };

export const KeyBoardKeyShortcut = ({ item, type = "short", style }: Props) => {
  if (type === "long") {
    return (
      <div className="row empty a-center tile ml-0 gray br-1 p-05 j-start fit-content" style={{ gap: "4px", padding: "2px" }}>
        {item?.shortCut?.map((shortCut, key) => {
          return (
            <>
              <div key={key} className="tile p-1 m-0 gray-light p-05 text-center fw-600 br-1 mw-80" style={{ ...style }}>
                <span className="" style={{ textTransform: "capitalize" }}>
                  {shortCut}
                </span>
              </div>
              {item?.shortCut?.length - 1 != key && "+"}
            </>
          );
        })}
      </div>
    );
  }
  if (type === "short") {
    return (
      <div
        style={{
          position: "absolute",
          right: "12px",
          top: "12px",
          fontSize: "0.85rem",
          fontWeight: 600,
          border: "1px solid var(--color-gray4)",
          color: "var(--color-gray16)",
          padding: "8px",
          borderRadius: "15px",
          background: "var(--color-gray2)",
          ...style,
        }}
      >
        {item?.shortCut?.map((shortCut, key) => {
          return (
            <span key={key} className="fw-600" style={{ textTransform: "capitalize" }}>
              {shortCut == "control" ? "Ctrl" : shortCut == "shift" ? "Shift" : shortCut == "alt" ? "Alt" : shortCut}
              {item?.shortCut?.length - 1 != key && "+"}
            </span>
          );
        })}
      </div>
    );
  }
};
